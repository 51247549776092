import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { usePoints } from "../context/PointsContext";
import { CiLogout } from "react-icons/ci";
import "../span.css";

const BannerHome = () => {
  const bannerData = useSelector((state) => state.movieData.bannerData);
  const backdropUrl = useSelector(
    (state) => state.movieData.imageURLs.backdropUrl
  ); // Use the backdrop URL
  const [currentImage, setCurrentImage] = useState(0);

  const points = usePoints();
  console.log("Points: ", points);
  const [isSubscribed, setIsSubscribed] = useState(points?.hasAccess);
  useEffect(() => {
    async function fetchPoints() {
      await points.fetchUserData();
    }
    setTimeout(() => {
      fetchPoints();
    }, 60000);
  }, []);

  useEffect(() => {
    setIsSubscribed(points?.hasAccess);
  }, [points]);

  const handleNext = () => {
    if (currentImage < bannerData.length - 1) {
      setCurrentImage((prev) => prev + 1);
    }
  };
  const handlePrevious = () => {
    if (currentImage > 0) {
      setCurrentImage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentImage < bannerData.length - 1) {
        handleNext();
      } else {
        setCurrentImage(0);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentImage, bannerData]);

  return (
    <section className="w-full h-full">
      <div className="flex min-h-full max-h-[95vh] overflow-hidden">
        {bannerData.map((data, index) => {
          return (
            <div
              key={data.id + "bannerHome" + index}
              className="min-w-full min-h-[450px] lg:min-h-full overflow-hidden relative group transition-all"
              style={{ transform: `translateX(-${currentImage * 100}%)` }}
            >
              <div className="w-full h-full">
                <img
                  src={backdropUrl + data.backdrop_path} // Use the larger backdrop URL
                  className="h-full w-full object-cover"
                  alt={data.title || data.name}
                />
              </div>

              {/***button next and previous image */}
              <div className="absolute top-0 w-full h-full hidden items-center justify-between px-4 group-hover:lg:flex">
                <button
                  onClick={handlePrevious}
                  className="bg-white p-1 rounded-full text-xl z-10 text-black"
                >
                  <FaAngleLeft />
                </button>
                <button
                  onClick={handleNext}
                  className="bg-white p-1 rounded-full text-xl z-10 text-black"
                >
                  <FaAngleRight />
                </button>
              </div>

              <div className="absolute top-0 w-full h-full bg-gradient-to-t from-neutral-900 to-transparent"></div>

              <div className="container mx-auto">
                <div className="w-full absolute bottom-0 max-w-md px-3">
                  <h2 className="font-bold text-3xl lg:text-5xl text-white drop-shadow-2xl ">
                    {data?.title || data?.name}
                  </h2>
                  <p className="text-ellipsis line-clamp-3 my-2">
                    {data.overview}
                  </p>
                  <div className="flex items-center gap-2">
                    <div className="flex items-center gap-1">
                      <p>Rating: {Number(data.vote_average).toFixed(1)}</p>
                      <FaStar />
                    </div>
                    <span>|</span>
                    <div>
                      <p>Views: {Number(data.popularity).toFixed(0)}</p>
                    </div>
                  </div>
                  {points?.hasAccess ? (
                    <Link to={"/" + data?.media_type + "/" + data.id}>
                      <button className="px-4 py-2 text-white font-bold rounded mt-4 bg-gradient-to-l from-green-700 to-green-500 shadow-md transition-all hover:scale-105">
                        Play Now
                      </button>
                    </Link>
                  ) : (
                    <button
                      className="px-4 py-2 text-white font-bold rounded mt-4 bg-gradient-to-l from-green-700 to-green-500 shadow-md transition-all hover:scale-105"
                      onClick={() => {
                        if (!points?.hasAccess) {
                          setIsSubscribed(false);
                        }
                      }}
                    >
                      Play Now
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BannerHome;
