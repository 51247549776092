import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { usePoints } from "../context/PointsContext";

const Card = ({ data, trending, index, media_type }) => {
  const posterUrl = useSelector((state) => state.movieData.imageURLs.posterUrl); // Use the poster URL
  const mediaType = data.media_type ?? media_type;

  // const points = usePoints();
  // useEffect(() => {
  //   async function fetchPoints() {
  //     await points.fetchUserData();
  //   }
  //   setTimeout(() => {
  //     fetchPoints();
  //   }, 60000);
  // }, []);
  // const handleCardClick = (e) => {
  //   if (!points?.hasAccess) {
  //     e.preventDefault();
  //     alert("Not Subscribed, SubScribe First");
  //     window.location.reload();
  //   }
  // };

  return (
    <Link
      to={"/" + mediaType + "/" + data.id}
      className="h-90 min-w-[230px] max-w-[230px] mx-auto overflow-auto block rounded relative overflow-y-hidden hover:scale-105 transition-all"
    >
      {data?.poster_path ? (
        <img src={posterUrl + data?.poster_path} alt="PosterLogo" />
      ) : (
        <div className="bg-neutral-800 h-full w-full flex justify-center items-center">
          No image found
        </div>
      )}
      <div className="absolute top-4">
        {trending && (
          <div className="text-white py-1 px-4 bg-gradient-to-l from-green-700 to-green-500 backdrop-blur-3xl rounded-r-full overflow-hidden">
            #{index} Trending
          </div>
        )}
      </div>
      <div className="absolute bottom-0 h-16 backdrop-blur-3xl w-full bg-black/60 p-2">
        <h2 className="text-ellipsis line-clamp-1 text-lg font-semibold text-white">
          {data?.title || data?.name}
        </h2>
        <div className="text-sm text-neutral-400 flex justify-between items-center">
          <p>
            {moment(data?.release_date || data?.first_air_date).format(
              "Do MMM YYYY"
            )}
          </p>
          <p className="bg-gradient-to-l from-green-700 to-green-500 px-1 rounded-full text-xs text-white flex items-center gap-1">
            {Number(data.vote_average).toFixed(1)}
            <FaStar />
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
