import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bannerData: [],
  imageURLs: {
    posterUrl: "", // For smaller images like posters
    backdropUrl: "", // For larger images like backdrops
  },
};

export const movieSlice = createSlice({
  name: "movie",
  initialState,
  reducers: {
    setBannerData: (state, action) => {
      state.bannerData = action.payload;
    },
    setImageURLs: (state, action) => {
      state.imageURLs.posterUrl = action.payload.posterUrl;
      state.imageURLs.backdropUrl = action.payload.backdropUrl;
    },
  },
});

export const { setBannerData, setImageURLs } = movieSlice.actions;

export default movieSlice.reducer;
