import { IoHome } from "react-icons/io5";
import { PiTelevisionFill } from "react-icons/pi";
import { BiSolidMoviePlay } from "react-icons/bi";
import { CiLogout } from "react-icons/ci";
export const navigation = [
  {
    label: "Tv Shows",
    href: "tv",
    icon: <PiTelevisionFill />,
  },
  {
    label: "Movies",
    href: "movie",
    icon: <BiSolidMoviePlay />,
  },
  {
    label: "Main",
    href: "https://turtlesmeme.io",
    icon: <CiLogout />,
  },
];

export const mobileNavigation = [
  {
    label: "Home",
    href: "/",
    icon: <IoHome />,
  },
  ...navigation,
];
