import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MobileNavigation from "./components/MobileNavigation";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBannerData, setImageURLs } from "./store/movieSlice"; // Updated action to set multiple image URLs

// Import the logo
import logo from "./assets/logo.png"; // Adjust the path if necessary

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true); // Preloader state

  const fetchTrendingData = async () => {
    try {
      const response = await axios.get("/trending/all/week");
      dispatch(setBannerData(response.data.results));
    } catch (error) {
      console.log("error fetching data:", error);
    }
  };

  const fetchConfiguration = async () => {
    try {
      const response = await axios.get("/configuration");

      // Store both image base URLs for different sizes (w500 for smaller and original for larger)
      const secureBaseUrl = response.data.images.secure_base_url;
      const posterUrl = secureBaseUrl + "w500"; // For poster-sized images (Card component)
      const backdropUrl = secureBaseUrl + "w1280"; // For full-sized images (BannerHome)

      // Dispatch both URLs to the Redux store
      dispatch(setImageURLs({ posterUrl, backdropUrl }));
    } catch (error) {
      console.log("error fetching configuration:", error);
    }
  };

  // Simulate loading time and hide preloader once data is fetched
  useEffect(() => {
    fetchTrendingData();
    fetchConfiguration();

    const timer = setTimeout(() => {
      setIsLoading(false); // Hide preloader after loading
    }, 2000); // Adjust preloader display time here

    return () => clearTimeout(timer);
  }, []);

  // Check if the current route is an explore page (movie, tv, search)
  const shouldShowFooter = !(
    location.pathname.includes("/movie") ||
    location.pathname.includes("/tv") ||
    location.pathname.includes("/search")
  );

  // Preloader component
  if (isLoading) {
    return (
      <div id="preloader">
        <img src={logo} alt="logo" /> {/* Use the imported logo */}
      </div>
    );
  }

  return (
    <main className="pb-14 lg:pb-0">
      <Header />
      <div className="min-h-[85vh]">
        <Outlet />
      </div>
      {shouldShowFooter && <Footer />}
      <MobileNavigation />
    </main>
  );
}

export default App;
