import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "../components/Card";
import _ from "lodash"; // Import lodash for debouncing
import { FaSearch } from "react-icons/fa"; // Search icon for the input field
import { usePoints } from "../context/PointsContext";
import { CiLogout } from "react-icons/ci";
import "../span.css";

const SearchPage = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const query = decodeURIComponent(location?.search?.slice(3)); // Decoding URL encoded space to actual space

  const points = usePoints();
  const [isSubscribed, setIsSubscribed] = useState(points?.hasAccess);
  useEffect(() => {
    async function fetchPoints() {
      await points.fetchUserData();
    }
    setTimeout(() => {
      fetchPoints();
    }, 60000);
  }, []);

  useEffect(() => {
    setIsSubscribed(points?.hasAccess);
  }, [points]);

  // Fetch data based on search query and page
  const fetchData = async () => {
    if (isLoading || (page > totalPages && totalPages !== 0)) return;

    setIsLoading(true);
    try {
      const response = await axios.get("/search/multi", {
        params: {
          query,
          page,
        },
      });

      // Append new data to the existing data array and update totalPages
      setData((prevData) => [...prevData, ...response.data.results]);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Reset data and page number when search query changes
  useEffect(() => {
    if (query) {
      setPage(1);
      setData([]);
      setTotalPages(0); // Reset total pages when search changes
      fetchData();
    }
  }, [location?.search]);

  // Fetch data when page number changes
  useEffect(() => {
    if (page > 1) {
      fetchData();
    }
  }, [page]);

  // Debounced scroll handler
  const handleScroll = _.debounce(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      !isLoading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, 500); // Adjust debounce delay as needed

  // Scroll to top when the user types
  const handleInputChange = _.debounce((value) => {
    navigate(`/search/?q=${encodeURIComponent(value)}`);
    // Scroll to top when user types
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 10); // Debounce to prevent excessive scrolling

  // Add scroll event listener on mount, remove on unmount
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, isLoading]);

  return (
    <div className="py-16">
      {/* Enhanced Search Bar */}
      <div className="lg:hidden w-full p-4 flex justify-center sticky top-16 z-30 bg-gradient-to-b from-black to-transparent">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="relative flex items-center w-full sm:w-2/3 lg:w-1/2 bg-gradient-to-r from-gray-800 to-gray-900 rounded-full shadow-lg border border-gray-700"
        >
          <input
            type="text"
            placeholder="Search for movies, TV shows..."
            onChange={(e) => handleInputChange(e.target.value)}
            value={query}
            className="w-full py-3 px-6 rounded-full outline-none text-lg bg-transparent text-gray-200 placeholder-gray-500"
          />
          <button
            type="submit"
            className="absolute right-6 text-gray-400 hover:text-white"
          >
            <FaSearch size={24} />
          </button>
        </form>
      </div>

      {/* Search Results */}
      <div className="container mx-auto">
        <h3 className="capitalize text-2xl text-center lg:text-3xl lg:text-left font-semibold my-5 text-white ">
          Search Results for "{query}"
        </h3>

        <div className="grid grid-cols-[repeat(auto-fit,230px)] gap-6 justify-center lg:justify-start">
          {data
            .filter(
              (searchData) =>
                searchData?.poster_path && searchData?.vote_average !== null
            ) // Filter out items without a poster or rating
            .map((searchData) => (
              <Card
                data={searchData}
                key={searchData.id + "SearchData"}
                media_type={searchData.explore}
              />
            ))}
        </div>

        {isLoading && <p>Loading more results...</p>}
      </div>
    </div>
  );
};

export default SearchPage;
