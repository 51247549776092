import React from "react";
import { Link, useNavigate } from "react-router-dom";
import footerLogo from "../assets/logo.png";
import Divider from "../components/Divider";

function Footer() {
  const navigate = useNavigate();

  const handleCategoryClick = (mediaType, genreId) => {
    navigate(`/${mediaType}?with_genres=${genreId}`);
  };

  return (
    <footer className="text-white text-center bg-black opacity-60 py-4">
      {/* Grid container to evenly distribute content */}
      <div className="container mx-auto px-4 grid grid-cols-1 lg:grid-cols-4 gap-10 text-left">
        {/* Movie Categories */}
        <div>
          <h3 className="text-lg font-bold mb-2">Popular Movie Categories</h3>
          <ul className="space-y-1">
            <li>
              <button
                className="hover:underline text-white"
                onClick={() => handleCategoryClick("movie", 28)}
              >
                Action Movies
              </button>
            </li>
            <li>
              <button
                className="hover:underline text-white"
                onClick={() => handleCategoryClick("movie", 35)}
              >
                Comedy Movies
              </button>
            </li>
            <li>
              <button
                className="hover:underline text-white"
                onClick={() => handleCategoryClick("movie", 18)}
              >
                Drama Movies
              </button>
            </li>
            <li>
              <button
                className="hover:underline text-white"
                onClick={() => handleCategoryClick("movie", 27)}
              >
                Horror Movies
              </button>
            </li>
          </ul>
        </div>

        {/* TV Show Categories */}
        <div>
          <h3 className="text-lg font-bold mb-2">Popular TV Show Categories</h3>
          <ul className="space-y-1">
            <li>
              <button
                className="hover:underline text-white"
                onClick={() => handleCategoryClick("tv", 10759)}
              >
                Action & Adventure TV Shows
              </button>
            </li>
            <li>
              <button
                className="hover:underline text-white"
                onClick={() => handleCategoryClick("tv", 16)}
              >
                Animation TV Shows
              </button>
            </li>
            <li>
              <button
                className="hover:underline text-white"
                onClick={() => handleCategoryClick("tv", 35)}
              >
                Comedy TV Shows
              </button>
            </li>
            <li>
              <button
                className="hover:underline text-white"
                onClick={() => handleCategoryClick("tv", 80)}
              >
                Crime TV Shows
              </button>
            </li>
          </ul>
        </div>

        {/* TRS Links */}
        <div>
          <h3 className="text-lg font-bold mb-2">Turtles Links</h3>
          <ul className="space-y-1">
            <li>
              <Link
                to="https://turtlesmeme.io"
                className="hover:underline text-white"
              >
                Turtles World
              </Link>
            </li>
            <li>
              <Link
                to="https://trsgame.com"
                className="hover:underline text-white"
              >
                Turtles Games
              </Link>
            </li>
            <li>
              <Link
                to="https://cap.turtlesmeme.io"
                className="hover:underline text-white"
              >
                Turtles MarketCap
              </Link>
            </li>
          </ul>
        </div>

        {/* Right section: Logo and description */}
        <div>
          <h3 className="text-lg font-bold">About Us</h3>
          <p className="text-sm w-[70%] lg:w-[350px]">
            At our platform, we believe in delivering an uninterrupted cinematic
            experience. Say goodbye to annoying ads and enjoy your favorite
            movies in stunning, high-definition resolution. Whether it's the
            latest blockbuster or a timeless classic, you can immerse yourself
            in crystal-clear visuals and exceptional sound quality. We’re
            committed to offering you the best streaming experience, with no
            distractions—just pure entertainment at the highest resolution. Sit
            back, relax, and enjoy the magic of movies, hassle-free!
          </p>
        </div>
      </div>

      <Divider />

      <div className="w-full flex justify-center">
        <img className="w-[150px]" src={footerLogo} alt="footerlogo" />
      </div>

      <p className="text-xs mt-4">Created By TRS Team</p>
    </footer>
  );
}

export default Footer;
