import React from "react";
import { mobileNavigation } from "../contants/navigation";
import { NavLink } from "react-router-dom";

const MobileNavigation = () => {
  return (
    <section className="lg:hidden h-14 bg-black/80 backdrop-blur-3xl  fixed bottom-0 w-full z-40">
      <div className="flex items-center justify-between h-full text-neutral-500">
        {mobileNavigation.map((nav, index) => {
          return (
            <NavLink
              key={nav.label + "mobilenavigation"}
              to={nav.href}
              className={({ isActive }) =>
                `px-4 flex h-full items-center flex-col justify-center ${
                  isActive && "text-neutral-50"
                }`
              }
            >
              <div className="text-2xl">{nav.icon}</div>
              <p className="text-xs">{nav.label}</p>
            </NavLink>
          );
        })}
      </div>
    </section>
  );
};

export default MobileNavigation;
