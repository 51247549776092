import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import userIcon from "../assets/user.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { navigation } from "../contants/navigation";
import { usePoints } from "../context/PointsContext";

function Header() {
  const location = useLocation();
  const removeSpace = location.search.slice(3).split("%20").join(" ");
  const [searchInput, setSearchInput] = useState(removeSpace);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchInput && searchInput.slice(0, 7) != "llet=0x") {
      navigate(`/search?q=${searchInput}`);
    } else {
      setSearchInput("");
    }
  }, [searchInput, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const points = usePoints();

  // Handle search button click based on screen size
  const handleSearchClick = () => {
    if (window.innerWidth < 1024) {
      // Mobile view (below 1024px)
      navigate("/search");
    } else {
      // On larger screens, the search input will be used
      if (searchInput) {
        navigate(`/search?q=${searchInput}`);
      }
    }
  };

  const [timeRemaining, setTimeRemaining] = useState("Expired");

  const subscriptionExpiry = localStorage.getItem("subscriptionExpiry");

  const calculateTimeRemaining = () => {
    if (subscriptionExpiry == null) {
      return "Expired";
    }
    const now = Date.now();
    const timeLeft = subscriptionExpiry * 1000 - now;

    if (timeLeft <= 0) {
      return "Expired";
    }

    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [subscriptionExpiry, points]);

  return (
    <header className="fixed top-0 w-full h-16 bg-black bg-opacity-95 z-40">
      <div className="container  mx-auto px-4 flex items-center h-full">
        <Link to={"/"}>
          <img src={Logo} alt="logo" width={50} />
        </Link>
        <nav className="hidden lg:flex justify-center items-center gap-1 ml-5">
          {navigation.map((nav) => (
            <div key={nav.label}>
              <NavLink
                to={nav.href}
                className={({ isActive }) =>
                  `px-3 hover:text-neutral-200 flex items-center gap-1 ${
                    isActive && "text-neutral-50"
                  }`
                }
              >
                {nav.label}
                {nav.icon}
              </NavLink>
            </div>
          ))}
        </nav>
        <div className="ml-auto flex items-center gap-6">
          <form className="flex items-center gap-2" onSubmit={handleSubmit}>
            {/* Search input visible on large screens */}
            <input
              type="text"
              placeholder="Search Here..."
              className="bg-transparent px-4 py-1 outline-none border-none hidden lg:block"
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
            />
            {/* Search button */}
            <button
              type="button"
              className="text-2xl text-white"
              onClick={handleSearchClick}
            >
              <IoSearchOutline />
            </button>
          </form>
          {/* <div className="w-9 h-9 rounded-full overflow-hidden cursor-pointer active:scale-50 transition-all">
            <img src={userIcon} alt="user" className="w-full h-full" />
          </div> */}
          {/* <div className="text-white text-lg font-mono bg-[#0f9015] px-2 py-1 rounded">
            {timeRemaining}
          </div> */}
        </div>
      </div>
    </header>
  );
}

export default Header;
