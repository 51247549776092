import React, { createContext, useState, useEffect } from "react";

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const walletFromUrl = urlParams.get("wallet");

    if (walletFromUrl) {
      setWalletAddress(walletFromUrl);
      localStorage.setItem("TRSWalletAddress", walletFromUrl);
    } else {
      const storedWallet = localStorage.getItem("TRSWalletAddress");
      if (storedWallet) {
        setWalletAddress(storedWallet);
      } else {
        setShowPopup(true);
      }
    }
  }, []);

  return (
    <WalletContext.Provider value={{ walletAddress }}>
      {children}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Connect Your Wallet First By Going to main site</h2>
            <button
              className="bg-red-500"
              onClick={() => {
                window.location.href = "https://turtlesmeme.io";
              }}
            >
              Main Site
            </button>
          </div>
        </div>
      )}
    </WalletContext.Provider>
  );
};
