import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "../components/Card";
import _ from "lodash"; // Import lodash for debouncing
import { usePoints } from "../context/PointsContext";
import { CiLogout } from "react-icons/ci";
import "../span.css";

const ExplorePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState([]);
  const [totalPageNo, setTotalPageNo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]); // For storing available categories
  const [selectedCategories, setSelectedCategories] = useState([]); // Store multiple selected categories
  const [sortOption, setSortOption] = useState("vote_count.desc"); // Default sort by highest votes
  const [showModal, setShowModal] = useState(false); // Modal state

  // Fetch categories (genres) for TV or movie
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`/genre/${params.explore}/list`);
      setCategories(response.data.genres);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const points = usePoints();
  const [isSubscribed, setIsSubscribed] = useState(points?.hasAccess);
  useEffect(() => {
    async function fetchPoints() {
      await points.fetchUserData();
    }
    setTimeout(() => {
      fetchPoints();
    }, 60000);
  }, []);

  useEffect(() => {
    setIsSubscribed(points?.hasAccess);
  }, [points]);

  const fetchData = async (pageNo) => {
    if (pageNo > totalPageNo && totalPageNo !== 0) return;

    setIsLoading(true);
    try {
      const response = await axios.get(`/discover/${params.explore}`, {
        params: {
          page: pageNo,
          with_genres: selectedCategories.join(","),
          sort_by: sortOption, // Sorting based on selected criteria
        },
      });
      const newMovies = response.data.results;

      // Filter out movies or shows without a poster or rating
      const filteredMovies = newMovies.filter(
        (item) => item.poster_path && item.vote_average !== null
      );

      setData((prevData) => {
        const combinedData = [...prevData, ...filteredMovies];
        const uniqueData = combinedData.reduce((acc, current) => {
          if (!acc.some((item) => item.id === current.id)) {
            acc.push(current);
          }
          return acc;
        }, []);
        return uniqueData;
      });

      if (totalPageNo === 0) {
        setTotalPageNo(response.data.total_pages);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories(); // Fetch categories when the component mounts
    fetchData(pageNo);
  }, [pageNo]);

  // Reset categories, sort option, and page number when switching between TV and Movies
  useEffect(() => {
    // Clear selected categories and reset sorting when switching explore types
    setSelectedCategories([]);
    setSortOption("vote_count.desc");
    setPageNo(1);
    setData([]);
    setTotalPageNo(0);

    fetchCategories(); // Fetch new categories when switching explore type
    fetchData(1); // Fetch new data for the selected explore type
  }, [params.explore]); // This ensures filters reset when switching between TV and Movies

  // Fetch data when selected categories or sort option changes
  useEffect(() => {
    fetchData(pageNo);
  }, [selectedCategories, sortOption]);

  const handleScroll = _.debounce(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      !isLoading
    ) {
      if (pageNo < totalPageNo) {
        setPageNo((prev) => prev + 1);
      }
    }
  }, 300);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, isLoading]);

  // Toggle category selection
  const handleCategoryClick = (categoryId) => {
    const updatedCategories = selectedCategories.includes(categoryId)
      ? selectedCategories.filter((id) => id !== categoryId)
      : [...selectedCategories, categoryId];

    setSelectedCategories(updatedCategories);
    setPageNo(1); // Reset the page when a category is selected or deselected
    setData([]); // Clear current data when category changes
  };

  const handleSortChange = (e) => {
    const value = e.target.value;
    setSortOption(value);
    setPageNo(1); // Reset page when sorting changes
    setData([]); // Clear data on sort change
  };

  const handleCardClick = (media) => {
    navigate(`/${params.explore}/${media.id}`); // For both movies and TV shows, navigate using ID only
  };

  return (
    <div className="py-16">
      <div className="container mx-auto">
        <h3 className="capitalize text-2xl text-center lg:text-3xl lg:text-left font-semibold my-3 text-white">
          Popular {params.explore}
        </h3>

        {/* Button to open filter modal */}
        <div className="text-center lg:text-left my-4">
          <button
            className="bg-black border-2 border-green-500 text-white py-2 px-6 rounded-md hover:bg-green-400 hover:scale-105 transition-all"
            onClick={() => setShowModal(true)}
          >
            Filter & Sort
          </button>
        </div>

        {/* Modal for Categories and Sort */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
            <div className="bg-black/90 border-2 border-green-500 text-white p-6 rounded-lg max-w-md w-full">
              <h2 className="text-xl mb-4">Filter by Categories</h2>
              <div className="flex flex-wrap gap-2 mb-6">
                {categories.map((category) => (
                  <button
                    key={category.id}
                    className={`px-4 py-2 rounded-md ${
                      selectedCategories.includes(category.id)
                        ? "bg-green-500 text-white"
                        : "bg-gray-700 text-gray-200"
                    } hover:bg-green-500 transition`}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {category.name}
                  </button>
                ))}
              </div>

              <h2 className="text-xl mb-2">Sort by</h2>
              <select
                className="px-4 py-2 text-lg bg-green-900 text-white w-full rounded-md mb-6"
                value={sortOption}
                onChange={handleSortChange}
              >
                <option value="vote_count.desc">Highest Votes (Default)</option>
                <option value="release_date.desc">Newest</option>
                <option value="release_date.asc">Oldest</option>
                <option value="vote_average.desc">Highest Rated</option>
                <option value="vote_average.asc">Lowest Rated</option>
              </select>

              {/* Close button */}
              <button
                className="bg-green-500 py-2 px-4 rounded-md hover:bg-green-600"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <div className="grid grid-cols-[repeat(auto-fit,230px)] gap-6 justify-center lg:justify-start">
          {data.map((exploreData) => (
            <div
              onClick={() => handleCardClick(exploreData)}
              key={exploreData.id}
            >
              <Card data={exploreData} media_type={params.explore} />
            </div>
          ))}
        </div>

        {isLoading && <p>Loading more...</p>}
      </div>
    </div>
  );
};

export default ExplorePage;
