// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBK02B1gsi_W3GaAIZNUzdSCSa1LYvLmbQ",
  authDomain: "turtlepoints-879e9.firebaseapp.com",
  projectId: "turtlepoints-879e9",
  storageBucket: "turtlepoints-879e9.appspot.com",
  messagingSenderId: "403333271526",
  appId: "1:403333271526:web:3b131293a3b8bcfe65d027",
  measurementId: "G-Y6B9656WQ1",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
