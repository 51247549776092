import React, { createContext, useContext, useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../constant/firebase";
import { CiLogout } from "react-icons/ci";
import "../span.css";

const PointsContext = createContext();

export const usePoints = () => useContext(PointsContext);

export const PointsProvider = ({ children }) => {
  const [points, setPoints] = useState(0);
  const [hasAccess, setHasAccess] = useState(false);
  const [subscriptionEnd, setSubscriptionEnd] = useState(null);
  const [timer, setTimer] = useState(null);
  const [mutexLock, setMutexLock] = useState(false);

  const walletAddress = localStorage.getItem("TRSWalletAddress");

  const fetchUserData = async () => {
    if (!walletAddress) return;

    try {
      //   const userDocRef = doc(db, 'TurtlePoints', walletAddress);
      const userDocRef = collection(db, "TurtlePoints");
      const q = query(userDocRef, where("walletAddress", "==", walletAddress));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((userDoc) => {
          const userData = userDoc.data();
          setPoints(userData.points || 0);
          setSubscriptionEnd(userData.subscriptionEnd || 0);
          localStorage.setItem("subscriptionExpiry", userData.subscriptionEnd);
          checkSubscription(userData.subscriptionEnd);
        });
      } else {
        console.error("No user data found.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const checkSubscription = (subscriptionEnd) => {
    const currentTime = Math.floor(Date.now() / 1000);
    setHasAccess(subscriptionEnd && subscriptionEnd > currentTime);
    return hasAccess;
  };

  const deductPoints = async () => {
    if (mutexLock) {
      return;
    }

    setMutexLock(true);

    try {
      // const newPoints = points - 5;
      const newSubscriptionEnd = Math.floor(Date.now() / 1000) + 24 * 60 * 60;

      const userDocRef = collection(db, "TurtlePoints");
      const q = query(userDocRef, where("walletAddress", "==", walletAddress));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (userDoc) => {
          const docRef = doc(db, "TurtlePoints", userDoc.id);
          const currPoints = userDoc.data().points;
          const currSub = userDoc.data().subscriptionEnd;
          if (checkSubscription(currSub)) {
            console.log("Already Subscribed");
            setMutexLock(false);
            return hasAccess;
          }
          if (currPoints < 5) {
            alert("Insufficient Tokens");
            setMutexLock(false);
            return;
          }
          const newPoints = currPoints - 5;
          await updateDoc(docRef, {
            points: newPoints,
            subscriptionEnd: newSubscriptionEnd,
          });
          setPoints(newPoints);
          setSubscriptionEnd(newSubscriptionEnd);
          localStorage.setItem("subscriptionExpiry", newSubscriptionEnd);
          setHasAccess(true);
          setMutexLock(false);
          return hasAccess;
        });
      } else {
        console.error("No user data found.");
        alert("Insufficient Tokens");
      }
      setMutexLock(false);
      return true;
    } catch (error) {
      console.error("Error updating user data:", error);
      setMutexLock(false);
      return false;
    }
  };

  const awardPoints = async () => {
    const newPoints = 0.17;
    const cooldownMinutes = 1;
    const now = Math.floor(Date.now() / 1000);

    try {
      const userPointsCollection = collection(db, "TurtlePoints");
      const q = query(
        userPointsCollection,
        where("walletAddress", "==", walletAddress)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (docSnapshot) => {
          const docRef = doc(db, "TurtlePoints", docSnapshot.id);
          const data = docSnapshot.data();
          const existingPoints = data.points || 0;
          const lastAwardedTime = data.timestamp || 0;

          if (now - lastAwardedTime < cooldownMinutes * 60) {
            console.log("Points were awarded recently, skipping...");
            return;
          }

          await updateDoc(docRef, {
            points: increment(newPoints),
            timestamp: now,
          });
          setPoints(existingPoints + newPoints);
          console.log(`Points updated for wallet ${walletAddress}`);
        });
      } else {
        await addDoc(userPointsCollection, {
          walletAddress: walletAddress,
          points: newPoints,
          timestamp: now,
        });
        console.log("New wallet added and points awarded");
      }
    } catch (error) {
      console.error("Error awarding points:", error);
    }
  };

  const startTimer = () => {
    let counter = 0;
    setTimer(
      setInterval(() => {
        counter++;
        // console.log(`Connected for ${counter} seconds`);
        if (counter >= 60) {
          awardPoints();
          counter = 0;
          clearInterval(timer);
        }
      }, 1000)
    );
  };

  const stopTimer = () => {
    if (timer) {
      clearInterval(timer);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      startTimer();
    } else {
      stopTimer();
    }
  }, [walletAddress]);

  return (
    <PointsContext.Provider
      value={{ points, hasAccess, deductPoints, fetchUserData }}
    >
      {children}
    </PointsContext.Provider>
  );
};
