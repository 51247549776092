import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./store/store";
import { PointsProvider } from "./context/PointsContext";
import { WalletProvider } from "./context/WallletContext";

// Setup Axios
axios.defaults.baseURL = "https://api.themoviedb.org/3";
axios.defaults.headers.common[
  "Authorization"
] = `Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkNTFiMTNiNjFjNjI4OGMzYWI5MDM2ZWIxMjIxYWE5NiIsIm5iZiI6MTcyNjk4NjIwNC4yOTQ4MzIsInN1YiI6IjY2ZWZiNzE5NzMwMGE1YmEyMTNiM2E5MCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.N5PsqyGQA55rpmyzuRTcFUxTlfSEF69FL21Fsk08JQU`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <WalletProvider>
    <PointsProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </PointsProvider>
  </WalletProvider>
  // /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
