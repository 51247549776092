import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetchDetails from "../hooks/useFetchDetails";
import useFetch from "../hooks/useFetch";
import { useSelector } from "react-redux";
import { FaStar } from "react-icons/fa";
import moment from "moment";
import Divider from "../components/Divider";
import HorizontalScrollCard from "../components/HorizontalScrollCard";
import { usePoints } from "../context/PointsContext";
import { CiLogout } from "react-icons/ci";
import "../span.css";

const DetailsPage = () => {
  const params = useParams();
  const { posterUrl, backdropUrl } = useSelector(
    (state) => state.movieData.imageURLs
  );
  const { data } = useFetchDetails(`/${params?.explore}/${params?.id}`);
  const { data: castData } = useFetchDetails(
    `/${params?.explore}/${params?.id}/credits`
  );
  const { data: similarData } = useFetch(
    `/${params?.explore}/${params?.id}/similar`
  );
  const { data: recommendatData } = useFetch(
    `/${params?.explore}/${params?.id}/recommendations`
  );

  const [selectedSeason, setSelectedSeason] = useState(1);
  const [selectedEpisode, setSelectedEpisode] = useState(1);
  const [totalEpisodes, setTotalEpisodes] = useState(10);
  const [trailerKey, setTrailerKey] = useState(null);
  const [showTrailer, setShowTrailer] = useState(false);
  const [selectedAPI, setSelectedAPI] = useState("vidsrc.me"); // Default API selection

  const points = usePoints();
  const [isSubscribed, setIsSubscribed] = useState(points?.hasAccess);
  useEffect(() => {
    async function fetchPoints() {
      await points.fetchUserData();
    }
    setTimeout(() => {
      fetchPoints();
    }, 60000);
  }, []);
  useEffect(() => {
    setIsSubscribed(points?.hasAccess);
  }, [points]);

  useEffect(() => {
    if (data?.number_of_seasons) {
      setTotalEpisodes(10);
    }
  }, [selectedSeason, data]);

  useEffect(() => {
    const fetchTrailer = async () => {
      try {
        const response = await fetch(
          `https://api.themoviedb.org/3/${params.explore}/${params.id}/videos`,
          {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJkNTFiMTNiNjFjNjI4OGMzYWI5MDM2ZWIxMjIxYWE5NiIsIm5iZiI6MTcyNjk4NjIwNC4yOTQ4MzIsInN1YiI6IjY2ZWZiNzE5NzMwMGE1YmEyMTNiM2E5MCIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.N5PsqyGQA55rpmyzuRTcFUxTlfSEF69FL21Fsk08JQU`,
              Accept: "application/json",
            },
          }
        );
        const result = await response.json();
        const trailer = result.results.find((vid) => vid.type === "Trailer");
        setTrailerKey(trailer ? trailer.key : null);
      } catch (error) {
        console.error("Error fetching trailer:", error);
      }
    };
    fetchTrailer();
  }, [params.explore, params.id]);

  const formatNumber = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + "B";
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    }
    return num;
  };

  const formatDuration = (runtime) => {
    const hours = Math.floor(runtime / 60);
    const minutes = runtime % 60;
    return `${hours}H ${minutes}M`;
  };

  const durationText =
    params.explore === "tv"
      ? `Seasons: ${data?.number_of_seasons || 1}`
      : `Duration: ${formatDuration(data?.runtime || 0)}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.id]);

  const handleCloseModal = (e) => {
    if (e.target.id === "trailer-modal") {
      setShowTrailer(false);
    }
  };

  const handleShare = async () => {
    const shareUrl = window.location.href; // The current page URL
    if (navigator.share) {
      try {
        await navigator.share({
          title: data?.title || data?.name,
          url: shareUrl,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      navigator.clipboard.writeText(shareUrl).then(() => {
        alert("Link copied to clipboard!");
      });
    }
  };

  return (
    <div>
      {/* Backdrop Image */}
      <div className="w-full h-[300px] relative">
        <div className="w-full h-full">
          <img
            src={backdropUrl + data?.backdrop_path}
            className="h-full w-full object-cover"
            alt={data?.title || data?.name}
          />
        </div>
        <div className="absolute w-full h-full top-0 bg-gradient-to-t from-neutral-900 to-transparent/5"></div>
      </div>

      {/* Poster and Movie Info */}
      <div className="container w-full mx-auto px-5 flex items-center flex-col gap-3 md:w-[85%] xl:w-[65%] lg:justify-center lg:items-end lg:flex-row lg:gap-10">
        <div className="-mt-52 lg:-mt-56 relative w-fit min-w-60">
          <img
            src={posterUrl + data?.poster_path}
            className="h-80 w-60 object-cover rounded-xl"
            alt={data?.title || data?.name}
          />
          <button
            className="mt-4 w-full text-white rounded py-2 bg-green-700 hover:bg-gradient-to-l from-green-800 to-green-500 transition-all"
            onClick={() => setShowTrailer(true)}
            disabled={!trailerKey}
          >
            {trailerKey ? "Watch Trailer" : "No Trailer Available"}
          </button>
        </div>
        <div className="text-center lg:text-left lg:-mt-32 z-10">
          <h2 className="text-white text-2xl lg:text-4xl font-bold">
            {data?.title || data?.name}
          </h2>
          <p className="text-neutral-400">{data?.tagline}</p>

          <Divider />
          <div className="flex items-center justify-center lg:justify-start gap-2">
            <p className="flex items-center gap-1">
              {Number(data?.vote_average).toFixed(1)}
              <FaStar />
            </p>
            <span>|</span>
            <p>Views: {formatNumber(Number(data?.vote_count))}</p>
            <span>|</span>
            <p>{durationText}</p>
          </div>
          <Divider />
          <div>
            <div className="w-[85%] mx-auto md:w-[95%] xl:w-[70%] lg:mx-0">
              <h3 className="text-xl font-bold text-white">Overview</h3>
              <p>{data?.overview}</p>
            </div>
            <Divider />
            <p>
              <span className="text-white">Director</span>:{" "}
              {castData?.crew[0]?.name}
            </p>
            <Divider />
            <div className="flex gap-1 my-4 text-center justify-center lg:justify-start">
              <p>Status: {data?.status}</p>
              <span>|</span>
              <p>
                Release Date:{" "}
                {moment(data?.release_date || data?.first_air_date).format(
                  "DD/MM/YY"
                )}
              </p>

              {params.explore === "movie" && data?.revenue && (
                <>
                  <span>|</span>
                  <p>Revenue: ${formatNumber(Number(data?.revenue))}</p>
                </>
              )}
            </div>
            <Divider />
          </div>
        </div>
      </div>

      {/* API Selection */}
      <div className="container mx-auto px-5 pt-10 flex items-center  justify-center gap-4">
        <label className="text-white">Video Source:</label>
        <select
          value={selectedAPI}
          onChange={(e) => setSelectedAPI(e.target.value)}
          className="px-4 py-2 bg-gradient-to-l from-green-700 to-green-500 bg-black text-white rounded-md"
        >
          <option value="vidsrc.me">Vidsrc.me</option>
          <option value="vidsrc.dev">Vidsrc.dev</option>
        </select>
      </div>

      {/* Season and Episode Selection for TV Shows */}
      {params.explore === "tv" && (
        <div className="container mx-auto px-5 pt-10 flex justify-center gap-4">
          <div className="flex flex-col items-center gap-2">
            <label className="text-white"></label>
            <select
              value={selectedSeason}
              onChange={(e) => setSelectedSeason(e.target.value)}
              className="px-4 py-2 bg-gradient-to-l from-green-700 to-green-500 bg-black text-white rounded-md"
            >
              {Array.from({ length: data?.number_of_seasons || 1 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  Season {i + 1}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col items-center gap-2">
            <label className="text-white"></label>
            <select
              value={selectedEpisode}
              onChange={(e) => setSelectedEpisode(e.target.value)}
              className="px-4 py-2 bg-gradient-to-l from-green-700 to-green-500 bg-black text-white rounded-md"
            >
              {Array.from({ length: totalEpisodes }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  Episode {i + 1}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* Embed video using iframe */}
      <div className="w-full h-[450px] mb-16 mt-4 md:w-[85%] xl:w-[65%] lg:h-[700px] lg:mx-auto">
        <iframe
          className="w-[95%] h-full rounded mx-auto"
          src={
            selectedAPI === "vidsrc.me"
              ? `https://vidsrc.me/embed/${params.explore}/${params.id}`
              : `https://vidsrc.dev/embed/${params.explore}/${params.id}${
                  params.explore === "tv"
                    ? `/${selectedSeason}/${selectedEpisode}`
                    : ""
                }`
          }
          allowFullScreen
          loading="lazy"
          title={data?.title || data?.name}
        ></iframe>
      </div>

      {/* Share Button */}
      <div className="text-center mb-8">
        <button
          className="px-4 py-2 bg-gradient-to-l from-green-700 to-green-500 text-white rounded hover:bg-blue-700 transition"
          onClick={handleShare}
        >
          Share this video
        </button>
      </div>

      <div>
        <HorizontalScrollCard
          data={similarData}
          heading={"Similar " + params?.explore + "s"}
          media_type={params?.explore}
        />
      </div>
      <div>
        <HorizontalScrollCard
          data={recommendatData}
          heading={"Recommended " + params?.explore + "s"}
          media_type={params?.explore}
        />
      </div>

      {/* Trailer Modal */}
      {showTrailer && (
        <div
          id="trailer-modal"
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          onClick={handleCloseModal}
        >
          <div className="relative w-[90%] lg:w-[70%] h-[70%] bg-black rounded-lg">
            <button
              className="absolute top-4 left-[50%] transform -translate-x-1/2 text-white bg-gradient-to-l from-green-700 to-green-500 px-6 py-2 rounded-full text-lg font-bold"
              onClick={() => setShowTrailer(false)}
            >
              Close Trailer
            </button>
            <iframe
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${trailerKey}`}
              allowFullScreen
              title="Trailer"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsPage;
